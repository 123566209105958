import React from 'react';
import { Star, CheckCircle2, ArrowRight, Heart, Sparkles, TrendingUp, Smile } from 'lucide-react';

const Practice: React.FC = () => {
  return (
    <div className="min-h-screen bg-gradient-to-b from-primary-50 via-white to-white">
      {/* Hero Section */}
      <div className="relative overflow-hidden">
        <div className="absolute inset-0">
          <img
            src="https://images.unsplash.com/photo-1606811841689-23dfddce3e95?auto=format&fit=crop&w=2000&q=80"
            alt="Beautiful Smile"
            className="w-full h-full object-cover"
          />
          <div className="absolute inset-0 bg-gradient-to-r from-primary-900/90 to-primary-800/80" />
        </div>
        
        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 py-24 sm:py-32">
          <div className="max-w-3xl">
            <h1 className="text-4xl sm:text-5xl font-bold text-white mb-6">
              Your Dream Smile Awaits
            </h1>
            <p className="text-xl text-primary-50 mb-8">
              Discover how our network of trusted cosmetic dentists can help you achieve the confident, radiant smile you've always wanted
            </p>
            <div className="flex flex-col sm:flex-row gap-4">
              <button
                onClick={() => window.location.href = '/'}
                className="bg-white text-primary-600 px-8 py-4 rounded-xl hover:bg-primary-50 transition-all transform hover:scale-105 inline-flex items-center justify-center gap-2 group"
              >
                Try Free Smile Analysis
                <ArrowRight className="group-hover:translate-x-1 transition-transform" />
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Benefits Section */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 py-16">
        <h2 className="text-3xl font-bold text-center mb-12">Why Choose Our Network</h2>
        <div className="grid md:grid-cols-3 gap-8">
          <div className="bg-white p-6 rounded-xl shadow-sm">
            <div className="w-12 h-12 bg-primary-100 rounded-lg flex items-center justify-center mb-4">
              <Heart className="text-primary-600" size={24} />
            </div>
            <h3 className="text-xl font-semibold mb-2">Experienced & Caring</h3>
            <p className="text-gray-600">Our dentists are not just experts in cosmetic dentistry—they're passionate about creating beautiful, natural-looking smiles that boost your confidence.</p>
          </div>
          
          <div className="bg-white p-6 rounded-xl shadow-sm">
            <div className="w-12 h-12 bg-primary-100 rounded-lg flex items-center justify-center mb-4">
              <Sparkles className="text-primary-600" size={24} />
            </div>
            <h3 className="text-xl font-semibold mb-2">Personalized Care</h3>
            <p className="text-gray-600">Every smile is unique. Get customized treatment plans that consider your specific goals, lifestyle, and budget.</p>
          </div>
          
          <div className="bg-white p-6 rounded-xl shadow-sm">
            <div className="w-12 h-12 bg-primary-100 rounded-lg flex items-center justify-center mb-4">
              <Smile className="text-primary-600" size={24} />
            </div>
            <h3 className="text-xl font-semibold mb-2">Advanced Technology</h3>
            <p className="text-gray-600">Experience state-of-the-art treatments and see your potential results before you start with our AI smile preview.</p>
          </div>
        </div>
      </div>

      {/* Transformations */}
      <div className="bg-primary-50 py-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6">
          <h2 className="text-3xl font-bold text-center mb-12">Real Smile Transformations</h2>
          <div className="grid md:grid-cols-3 gap-8">
            {[
              {
                name: "Sarah M.",
                treatment: "Veneers & Whitening",
                image: "https://images.unsplash.com/photo-1606811841689-23dfddce3e95?auto=format&fit=crop&w=150&h=150&q=80",
                content: "I never thought I could love my smile this much! The entire process was so comfortable and the results are amazing."
              },
              {
                name: "David R.",
                treatment: "Invisalign & Bonding",
                image: "https://images.unsplash.com/photo-1545167622-3a6ac756afa4?auto=format&fit=crop&w=150&h=150&q=80",
                content: "The transformation exceeded my expectations. My dentist really listened to what I wanted and delivered perfectly."
              },
              {
                name: "Emma L.",
                treatment: "Complete Smile Makeover",
                image: "https://images.unsplash.com/photo-1494790108377-be9c29b29330?auto=format&fit=crop&w=150&h=150&q=80",
                content: "Not only do I love my new smile, but the confidence it's given me has changed my life. Worth every penny!"
              }
            ].map((story, index) => (
              <div key={index} className="bg-white p-6 rounded-xl shadow-sm">
                <div className="flex items-center gap-4 mb-4">
                  <img
                    src={story.image}
                    alt={story.name}
                    className="w-12 h-12 rounded-full object-cover"
                  />
                  <div>
                    <div className="font-semibold">{story.name}</div>
                    <div className="text-sm text-primary-600">{story.treatment}</div>
                  </div>
                </div>
                <p className="text-gray-600 mb-4">{story.content}</p>
                <div className="flex text-primary-400">
                  {[...Array(5)].map((_, i) => (
                    <Star key={i} size={16} fill="currentColor" />
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Treatment Options */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 py-16">
        <div className="text-center mb-12">
          <h2 className="text-3xl font-bold mb-4">Transform Your Smile</h2>
          <p className="text-gray-600">Discover the perfect treatment for your smile goals</p>
        </div>
        
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6">
          {[
            {
              title: "Teeth Whitening",
              description: "Brighten your smile with professional whitening treatments that are safe and effective.",
              time: "1-2 sessions"
            },
            {
              title: "Porcelain Veneers",
              description: "Transform your smile with custom-made veneers that look and feel natural.",
              time: "2-3 visits"
            },
            {
              title: "Invisalign",
              description: "Straighten your teeth discreetly with custom clear aligners.",
              time: "6-18 months"
            },
            {
              title: "Dental Bonding",
              description: "Fix minor imperfections and gaps with natural-looking bonding.",
              time: "1 visit"
            },
            {
              title: "Smile Makeover",
              description: "Combine multiple treatments for a complete smile transformation.",
              time: "Customized"
            },
            {
              title: "Gum Contouring",
              description: "Reshape your gumline for a more balanced smile.",
              time: "1-2 visits"
            }
          ].map((treatment, index) => (
            <div key={index} className="bg-white p-6 rounded-xl shadow-sm hover:shadow-md transition-shadow">
              <h3 className="text-xl font-semibold mb-2">{treatment.title}</h3>
              <p className="text-gray-600 mb-4">{treatment.description}</p>
              <div className="text-sm text-primary-600">
                Average treatment time: {treatment.time}
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* CTA Section */}
      <div className="bg-primary-900 text-white py-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 text-center">
          <h2 className="text-3xl font-bold mb-4">Ready to Love Your Smile?</h2>
          <p className="text-xl text-primary-100 mb-8">
            Take the first step towards your dream smile with our free AI analysis
          </p>
          <button
            onClick={() => window.location.href = '/'}
            className="bg-white text-primary-600 px-8 py-4 rounded-xl hover:bg-primary-50 transition-all transform hover:scale-105 inline-flex items-center gap-2 group"
          >
            Get Your Free Smile Analysis
            <ArrowRight className="group-hover:translate-x-1 transition-transform" />
          </button>
          <p className="text-sm text-primary-200 mt-4">
            No obligation • Takes 2 minutes • See your potential results instantly
          </p>
        </div>
      </div>
    </div>
  );
};

export default Practice;
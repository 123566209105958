import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Menu, X } from 'lucide-react';
import logo from '../assets/logo1.png';

const Navbar: React.FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [showShareToast, setShowShareToast] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleShare = async () => {
    const shareData = {
      title: 'ScanSmile - AI Smile Analysis',
      text: 'Get your personalized smile analysis with ScanSmile!',
      url: window.location.href
    };

    try {
      if (navigator.share) {
        await navigator.share(shareData);
      } else {
        await navigator.clipboard.writeText(window.location.href);
        setShowShareToast(true);
        setTimeout(() => setShowShareToast(false), 2000);
      }
    } catch (err) {
      console.error('Error sharing:', err);
    }
  };

  return (
    <nav className="bg-white shadow-sm relative">
      <div className="container mx-auto px-4">
        <div className="flex justify-between items-center h-16">
          <Link to="/" className="flex items-center space-x-2">
            <img src={logo} alt="ScanSmile" className="h-12 w-auto" />
            <span className="font-bold text-xl">ScanSmile</span>
          </Link>

          <div className="flex items-center gap-4">
            <button
              onClick={handleShare}
              className="text-gray-600 hover:text-primary-600 text-sm font-medium transition-colors"
            >
              Share
            </button>
            
            <button 
              onClick={toggleMenu}
              className="text-gray-600 hover:text-primary-600"
            >
              {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
            </button>
          </div>
        </div>

        {isMenuOpen && (
          <div className="absolute top-16 left-0 right-0 bg-white shadow-lg z-50">
            <div className="flex flex-col space-y-4 p-4">
              <Link 
                to="/practice" 
                className="text-gray-600 hover:text-primary-600 py-2 flex items-center gap-2"
                onClick={() => setIsMenuOpen(false)}
              >
                Smile Transformations
              </Link>
              <Link 
                to="/work-with-us" 
                className="text-gray-600 hover:text-primary-600 py-2 flex items-center gap-2"
                onClick={() => setIsMenuOpen(false)}
              >
                Work with Us
              </Link>
              <Link 
                to="/faq" 
                className="text-gray-600 hover:text-primary-600 py-2 flex items-center gap-2"
                onClick={() => setIsMenuOpen(false)}
              >
                FAQ
              </Link>
              <Link 
                to="/privacy" 
                className="text-gray-600 hover:text-primary-600 py-2 flex items-center gap-2"
                onClick={() => setIsMenuOpen(false)}
              >
                Privacy
              </Link>
              <Link 
                to="/terms" 
                className="text-gray-600 hover:text-primary-600 py-2 flex items-center gap-2"
                onClick={() => setIsMenuOpen(false)}
              >
                Terms
              </Link>
            </div>
          </div>
        )}

        {/* Share Toast */}
        {showShareToast && (
          <div className="fixed bottom-4 left-1/2 transform -translate-x-1/2 bg-gray-800 text-white px-4 py-2 rounded-lg shadow-lg text-sm">
            Link copied to clipboard!
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;